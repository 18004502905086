import { useEffect, useState } from 'react';
import NavbarButton from '../../assets/images/navbar-button.svg'
import { useTranslation } from 'react-i18next';

const Header = () => {
    const [open,setOpen] = useState(false);
    const [showButton,setShowButton] = useState(false);
    const [show,setShow] = useState(false);
    const [contact,setContact] = useState(false);
    const [whatsapp,setWhatsapp] = useState(false);
    const [sticky,setSticky] = useState(false);
    const { t,i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 200) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        });
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
        window.addEventListener('scroll', () => {
            const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
            const scrollPercentage = (window.scrollY / scrollHeight) * 100;
            if (scrollPercentage > 80) {
                setWhatsapp(true);
            } else {
                setWhatsapp(false);
            }
        });
    }, []);
    return(
        <>  
            <img className='header-left-bamboo' src={require('../../assets/images/header-left.png')} id='top'/>
            <img className='header-right-planet' src={require('../../assets/images/header-right.png')} />

            <div className={sticky == true ? "container-fluid sticky-header " : "container-fluid"}>
                <div className="row webView">
                    <div className="col-lg-5 col-xl-4 d-flex justify-content-end">
                        <div className="header-left-content">
                            <a href="#hakkimizda" className="header-button">
                                {t('menuAbout')}
                            </a>
                            <a href="#ne-sunuyoruz" className={"header-button"}>
                                {t('menuOffer')}
                            </a>
                            <a href="#iletisim" className={"header-button"}>
                                {t('menuContact')}
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-4 d-flex aling-items-center justify-content-center">
                        <img className='logo' src={require('../../assets/images/bambugames.png')}/>
                    </div>
                    <div className="col-4 d-flex justfy-content-center align-items-center">
                        <div style={{position:'relative',left:'25%'}}>
                            <img src={require('../../assets/images/header-right-photo.png')}/>
                            <div className='social-media'>
                            <div className="d-flex flex-row align-items-center" style={{gap:'0px 10px'}}>
                                <a className={i18n.language == "en" ? "" : "header-languege-active" } style={{cursor:'pointer',color:'#ffffff'}} onClick={()=>changeLanguage('tr')}>TR</a>
                                <a className={i18n.language == "en" ? "header-languege-active" : "" } style={{cursor:'pointer',color:'#ffffff'}} onClick={()=>changeLanguage('en')}>EN</a>
                            </div>
                                {/* <a>
                                    <img src={require('../../assets/images/youtube.png')}/>
                                </a>
                                <a>
                                    <img src={require('../../assets/images/instagram.png')}/>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={sticky == true ? 'container-fluid mobileView align-items-center justify-content-center sticky-header' : 'container-fluid mobileView align-items-center justify-content-center'}>
                <div className='row mt-2 w-100'>
                    <div className='col-4 d-flex align-items-center'>
                        <a onClick={()=>setOpen(!open)}> <img className='position-relative' style={{zIndex:11}} src={NavbarButton}/> </a>
                    </div>
                    <div className='col-4 d-flex align-items-center justify-content-center'>
                        <img className='logo'  style={{zIndex:11}} src={require('../../assets/images/bambugames.png')} />
                    </div>
                    <div className="col-4 d-flex justfy-content-center align-items-center">
                        <div style={{position:'relative',left:'25%'}}>
                            <img  style={{zIndex:11}} className='social-rock' src={require('../../assets/images/header-right-photo.png')}/>
                            <div className='social-media' style={{zIndex:11}}>
                                <a className={i18n.language == "en" ? "" : "header-languege-active" } style={{cursor:'pointer',color:'#ffffff'}} onClick={()=>changeLanguage('tr')}>TR</a>
                                <a className={i18n.language == "en" ? "header-languege-active" : "" } style={{cursor:'pointer',color:'#ffffff'}} onClick={()=>changeLanguage('en')}>EN</a>
                                {/* <a>
                                    <img src={require('../../assets/images/youtube.png')}/>
                                </a>
                                <a>
                                    <img src={require('../../assets/images/instagram.png')}/>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={sticky == true ? 'container-fluid tabletView align-items-center justify-content-center sticky-header' : 'container-fluid tabletView align-items-center justify-content-center'}>
                <div className='row mt-2 w-100'>
                    <div className='col-4 d-flex align-items-center'>
                        <a onClick={()=>setOpen(!open)}> <img className='position-relative' src={NavbarButton}/> </a>
                    </div>
                    <div className='col-4 d-flex align-items-center justify-content-center'>
                        <img className='logo'  style={{zIndex:11}} src={require('../../assets/images/bambugames.png')} />
                    </div>
                    <div className="col-4 d-flex justfy-content-center align-items-center">
                        <div style={{position:'relative',left:'25%'}}>
                            <img  style={{zIndex:11}} className='social-rock' src={require('../../assets/images/header-right-photo.png')}/>
                            <div className='social-media'  style={{zIndex:11}}>
                                <a className={i18n.language == "en" ? "" : "header-languege-active" } style={{cursor:'pointer',color:'#ffffff'}} onClick={()=>changeLanguage('tr')}>TR</a>
                                <a className={i18n.language == "en" ? "header-languege-active" : "" } style={{cursor:'pointer',color:'#ffffff'}} onClick={()=>changeLanguage('en')}>EN</a>
                                {/* <a>
                                    <img src={require('../../assets/images/youtube.png')}/>
                                </a>
                                <a>
                                    <img src={require('../../assets/images/instagram.png')}/>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <a href='https://wa.me/905422151842?text=Merhaba%20Bambu%20Games%20masas%C4%B1%20hakk%C4%B1nda%20bilgi%20almak%20istiyorum.' target='_blank' className={whatsapp == true ? 'whatsapp d-none' : 'whatsapp'} >
                <img src={require('../../assets/images/whatsapp.png')}/>
            </a> */}
            <div className={open == true ? "header-menu" : "header-menu-close"}>
                <a onClick={()=>setOpen(!open)} href='#hakkimizda'>{t('menuAbout')}</a>
                <a onClick={()=>setOpen(!open)} href='#ne-sunuyoruz'>{t('menuOffer')}</a>
                <a onClick={()=>setOpen(!open)} href='#iletisim'>{t('menuContact')}</a>
                <div className='d-flex flex-column align-items-center' style={{gap:'10px 20px',marginTop:'40px'}}>
                    <a className='text-center'> Odunluk Mah. Odunluk Cad. <br/> N0:13/C, Nilüfer/BURSA </a> 
                    <div className='d-flex flex-row align-items-center'>
                        <img src={require('../../assets/images/phone-icon.png')}/>
                        <a className='position-relative' style={{zIndex:999}} href='tel:+905422151842'>0 (542) 215 18 42</a>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <img src={require('../../assets/images/mail-icon.png')}/>
                        <a>info@bambugames.com.tr</a>
                    </div>
                </div>
            </div>
            <a className={showButton == true ? 'up-arrow' : 'up-arrow goster'} href='#top'> <img src={require('../../assets/images/up-arrow.png')} /> </a>
        </>
    )
}

export default Header;