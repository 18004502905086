import React from "react";
import  ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Home from "./pages/Home/home.js"
import Table from "./pages/Tables/table.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/header.css';
import './css/footer.css'
import './css/style.css';
import './css/media.css';
import './i18n'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/",
    element: <Home/>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);