import Header from "../Layouts/header"
import Footer from "../Layouts/footer";
import { ToastContainer, toast } from 'react-toastify';
import { object, string } from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import axios from "axios";
import GamePdf from "../../assets/PDF/BambuGames_Katalog.pdf"
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,Autoplay} from 'swiper/modules';
import { GoogleMap, useLoadScript, Marker , InfoWindow} from '@react-google-maps/api';
import table from '../../assets/images/table.svg';
import activeTable from '../../assets/images/active-table.svg';
import passiveTable from '../../assets/images/passive-table.svg';

const Home = () => {
    const { t,i18n } = useTranslation();
    const [games,setGames] = useState();
    const [leftSwiper,setLeftSwiper] = useState();
    const [rightSwiper,setRightSwiper] = useState();

    const getGames = async () => {
        const config = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            url: `/games/`
        };
        const response = await axios(config)
            .then((response) => {
                setGames(response.data.data);
            })
            .catch((error) => {
                return error.response;
            }
        );
    }

    const fetchData = async () => {
        try {
            const half = Math.ceil(games.length / 2);
            setLeftSwiper(games.slice(0, half));
            setRightSwiper(games.slice(half));
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(()=>{
       getGames(); 
    },[])
    useEffect(()=>{
        // console.log("games",games);
        fetchData();
    },[games])

    axios.defaults.baseURL = 'https://api.bambugames.com.tr/api';

    const [cafes,setCafes] = useState();
    // const libraries = ['places'];
    const mapContainerStyle = {
        width: '100%',
        height: '70vh',
        borderRadius: '10px',
        border: '2px solid #ffffff'
    };
    const center = {
        lat: 40.211237, // default latitude
        lng: 28.992714, // default longitude
    };
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDQmAo3BBhOb6ow7xmNdgDZV5TF8uWKGJQ',
        // libraries,
    });
    const getCafes = async () => {
        const config = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTBkODFmMjYxZjBiNzE4MWFiNjgyMTUiLCJpYXQiOjE3MDE3MDMxMTcsImV4cCI6MTcwMjMwNzkxN30.C2cEnVUe1Jj5Ul1zrYOl9nk0pavXAI1PVgkcoZw-Yt4",
            },
            url: `/cafes/`
        };
        const response = await axios(config)
            .then((response) => {
                // console.log("response",response.data);
                setCafes(response.data.data);
            })
            .catch((error) => {
                return error.response;
            }
        );
    }
    const [activeMarker, setActiveMarker] = useState(null);
    
    useEffect(()=>{
       getCafes(); 
    },[]);
    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
            setActiveMarker(marker);
    };
    if (loadError) {
        getCafes(); 
        return <div>Error loading maps</div>;
    }
    
    if (!isLoaded) {
    return <div>Loading maps</div>;
    }

    // useEffect(()=>{
    //     /* eslint-disable */
    // },[])

    const onSubmit = (e) => {
        e.preventDefault();
        let json = {}
        const data = new FormData(e.target);

        data.forEach((value,key)=>{
            json[key] = value;
        })
        if (i18n.language == 'tr') {
            const schema = object().shape({
                name: string("Sadece metin yazabilirsiniz").required("İsim giriniz!"),
                mail: string("Sadece metin yazabilirsiniz").email("Email formatı yanlış!").required("Email giriniz!"),
                profile: string("Sadece metin yazabilirsiniz").required("Müşteri pofili giriniz!"),
                subject: string("Sadece metin yazabilirsiniz").required("Konu giriniz!"),
                message: string("Sadece metin yazabilirsiniz").required("Mesaj giriniz!"),
            });
            schema.validate(json).then(r=>{
                axios.post('/api/send-contact-form',json).then((res)=>{
                    toast.success(res.data.message);
                }).catch((err)=>{
                    if (err.response.status == 400) {
                        toast.error(err.response.data)    
                    }
                    else{
                        toast.error(err.message);
                    }
                })
            }).catch((err)=>{
                toast.error(err.message);
            })
        }
        else{
            const schema = object().shape({
                name: string("You can only write text").required("Enter name!"),
                mail: string("You can only write text").email("Email format is wrong!").required("Enter your email!"),
                profile: string("You can only write text").required("Enter your customer profile!"),
                subject: string("You can only write text").required("Enter subject!"),
                message: string("You can only write text").required("Enter message!"),
            });
            schema.validate(json).then(r=>{
                axios.post('/api/send-contact-form',json).then((res)=>{
                    toast.success(res.data.message);
                }).catch((err)=>{
                    if (err.response.status == 400) {
                        toast.error(err.response.data)    
                    }
                    else{
                        toast.error(err.message);
                    }
                })
            }).catch((err)=>{
                toast.error(err.message);
            })
        }
        
        
    }   
    return(
        <>
            <div className="container-fluid position-relative first-background">
                <Header/>
                <div className="first-content  mt-5">
                    <div className="row">
                        <div className="col-md-6 col-12 d-flex">
                            <div className="row">
                                <div className="col-4"></div>
                                <div className="col-8 webView flex-column justify-content-center" style={{gap:'20px 0px'}}>
                                    <span>{t('firstSpan')} <img src={require('../../assets/images/rocket.png')}/> </span>
                                    {
                                        i18n.language == 'en' ? <h1>A <a> <b><i>MODERN</i></b> </a> APPROACH TO YOUR FAVORITE GAMES</h1> : <h1>EN SEVDİĞİNİZ OYUNLARA <a> <b><i>MODERN</i></b> </a> BİR YAKLAŞIM</h1> 
                                    }
                                    <p>{t('firstAreaDescription')}</p>
                                    {/* <p>Alıştığınız masa oyunlarını, vizyoner yaklaşımımız ile harmanlayarak sizlere <br/> yepyeni bir tecrübe sunuyoruz.</p>
                                    <p>İster tek başınıza, ister çift olarak, ister dört kişilik arkadaş gruplarınızla bu yeni <br/> tecrübenin tadını çıkarın.</p> */}
                                    <div className="button-area position-relative">
                                        <a href="#qrcode" className="pink-button position-relative"> {t('firstAreaPingButton')} <img src={require('../../assets/images/arrow.png')}/> </a>
                                        <a href="#iletisim" className="purpule-button position-relative">{t('firstAreaPurpleButton')} <img src={require('../../assets/images/arrow.png')}/> </a>
                                    </div>
                                </div> 
                                <div className="col-8 tabletView flex-column" style={{gap:'10px 0px'}}>
                                    <span>{t('firstSpan')}<img src={require('../../assets/images/rocket.png')}/> </span>
                                    {
                                        i18n.language == 'en' ? <h1>A <a> <b><i>MODERN</i></b> </a> APPROACH TO YOUR FAVORITE GAMES</h1> : <h1>EN SEVDİĞİNİZ OYUNLARA <a> <b><i>MODERN</i></b> </a> BİR YAKLAŞIM</h1> 
                                    }
                                    <p>{t('firstAreaDescription')}</p>
                                    {/* <p>Alıştığınız masa oyunlarını, vizyoner yaklaşımımız ile harmanlayarak sizlere yepyeni bir tecrübe sunuyoruz.</p>
                                    <p>İster tek başınıza, ister çift olarak, ister dört kişilik arkadaş gruplarınızla bu yeni tecrübenin tadını çıkarın.</p> */}
                                    <div className="button-area">
                                        <a href="#qrcode" className="pink-button position-relative"> {t('firstAreaPingButton')} <img src={require('../../assets/images/arrow.png')}/> </a>
                                        <a href="#iletisim" className="purpule-button position-relative">İletişime Geçin <img src={require('../../assets/images/arrow.png')}/> </a>
                                    </div>
                                </div>
                                <div className="col-8 mobileView flex-column align-items-center" style={{gap:'20px 0px'}}>
                                    <span>{t('firstSpan')}<img src={require('../../assets/images/rocket.png')}/> </span>
                                    {
                                        i18n.language == 'en' ? <h1>A <a> <b><i>MODERN</i></b> </a> APPROACH TO YOUR FAVORITE GAMES</h1> : <h1>EN SEVDİĞİNİZ OYUNLARA <a> <b><i>MODERN</i></b> </a> BİR YAKLAŞIM</h1> 
                                    }
                                    <p>{t('firstAreaDescription')}</p>
                                    {/* <p>Alıştığınız masa oyunlarını, vizyoner yaklaşımımız ile harmanlayarak sizlere yepyeni bir tecrübe sunuyoruz.</p>
                                    <p>İster tek başınıza, ister çift olarak, ister dört kişilik arkadaş gruplarınızla bu yeni tecrübenin tadını çıkarın.</p> */}
                                    <div className="button-area">
                                        <a href="#qrcode" className="pink-button"> {t('firstAreaPingButton')} <img src={require('../../assets/images/arrow.png')}/> </a>
                                        <a href="#iletisim" className="purpule-button">{t('firstAreaPurpleButton')}<img src={require('../../assets/images/arrow.png')}/> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mobile-margin d-flex align-items-center justify-content-center" style={{position:'relative'}}>
                            <img style={{maxWidth:'100%'}} src={require('../../assets/images/first-content-img.png')}/>
                            <img className="right-bambu" src={require('../../assets/images/right-bambu.png')} />
                            <img className="rock webView" src={require('../../assets/images/rock.png')} id="hakkimizda"/>
                        </div>
                    </div>
                </div>
                <img className="left-img" src={require('../../assets/images/aboutus-left.png')} />
                <div className="row mt-5 position-relative" >
                    <div className="col-12 second-content">
                        <span>
                            <img src={require('../../assets/images/ship.png')}/>
                            BAMBUGAMES
                        </span>
                        <h1>{t('menuAbout')}</h1>
                        <img className="position-relative" src={require('../../assets/images/hakkimizda.png')}/>
                        <p className="position-relative"> <b>Bambu Games</b> {t('aboutUsDescription')}<br></br>{t('aboutUsDescription2')}</p>
                    </div>
                    <div className="col-12" style={{marginTop:200,marginBottom:100}}>
                        <h1 className="text-center games-title">{t('games')}</h1>
                    </div>
                    <div className="col-12">
                        {
                            leftSwiper && leftSwiper.length > 0 && (
                                <Swiper 
                                    // slidesPerView={6}
                                    breakpoints={
                                        {
                                            0:{
                                                slidesPerView: 1.5
                                            },
                                            520:{
                                                slidesPerView: 2
                                            },
                                            768: {
                                                slidesPerView: 3,
                                            },
                                            1024: {
                                                slidesPerView: 4,
                                            },
                                            1400 : {
                                                slidesPerView: 5,
                                            },
                                            1700: {
                                                slidesPerView: 6,
                                            },
                                        }
                                    }
                                    spaceBetween={15}
                                    centeredSlides={true}
                                    autoplay={true}
                                    loop={true}
                                    modules={[Autoplay]}
                                    className="mySwiper"
                                >
                                    {
                                        leftSwiper?.map((item,index) => (
                                            <SwiperSlide key={index}>
                                                <img className="swiper-img" src={`https://api.bambugames.com.tr/uploads/game-icons/${item.icon}.png`} />
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            )
                        }
                        {
                            rightSwiper && rightSwiper.length > 0 && (
                                <Swiper 
                                    // slidesPerView={6}
                                    breakpoints={
                                        {
                                            0:{
                                                slidesPerView: 1.5
                                            },
                                            520:{
                                                slidesPerView: 2
                                            },
                                            768: {
                                                slidesPerView: 3,
                                            },
                                            1024: {
                                                slidesPerView: 4,
                                            },
                                            1400 : {
                                                slidesPerView: 5,
                                            },
                                            1700: {
                                                slidesPerView: 6,
                                            },
                                        }
                                    }
                                    spaceBetween={15}
                                    centeredSlides={true}
                                    loop={true}

                                    autoplay={{
                                        disableOnInteraction: true,
                                        //waitForTransition: false,
                                        reverseDirection: true,
                                    }}
                                    modules={[Autoplay]}
                                    initialSlide={rightSwiper?.length}
                                    className="mySwiper2"
                                >
                                    {
                                        rightSwiper?.map((item,index) => (
                                            <SwiperSlide key={index}>
                                                <img className="swiper-img" src={`https://api.bambugames.com.tr/uploads/game-icons/${item.icon}.png`} />
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            )
                        }
                    </div>
                    <div className="container" style={{marginTop:200}}>
                        <div className="row d-flex flex-row align-items-center justify-content-center">
                            <div className="col-md-8 col-12">
                                <h1 className="text-center games-title mb-5">{t('tables')}</h1>
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={5}
                                    onClick={() => setActiveMarker(null)}
                                    center={center}
                                >
                                    {
                                        cafes?.map((cafe,key)=>(
                                            <Marker key={key} icon={table} position={{
                                                    lat: cafe.latitude,
                                                    lng: cafe.longitude
                                                }}
                                                onClick={()=>handleActiveMarker(cafe._id)}
                                            > 
                                            {activeMarker === cafe._id ? (
                                                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                                    <div className='container-fluid'>
                                                        <div className='row justify-content-end'>
                                                            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                                                                <img style={{ width:"50%" }} className='logo' src={"https://api.bambugames.com.tr/"+cafe.file }/>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <h3 className='map-title'>{cafe.name}</h3>
                                                                <p className='map-text'>{cafe.address}</p>
                                                            </div>
                                                            <div className='col-md-6 col-12 d-flex align-items-center justify-content-center flex-column' style={{gap:'10px 0px'}}>
                                                                <div className='d-flex align-items-start justify-content-md-start justify-content-center flex-row w-100'>
                                                                    <img style={{marginRight:'10px'}} src={activeTable} />
                                                                    <h3 className='map-title' style={{marginBottom:0}}>{cafe.tables.count-cafe.tables.activeCount} Masa Dolu</h3>
                                                                </div>
                                                                <div className='d-flex align-items-center justify-content-md-start justify-content-center flex-row w-100'>
                                                                    <img style={{marginRight:'10px'}} src={passiveTable} />
                                                                    <h3 className='map-title' style={{marginBottom:0}}>{cafe.tables.activeCount} Masa Boş</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </InfoWindow>
                                            ) : null}
                                            </Marker>
                                        )
                                        )
                                    }
                                </GoogleMap>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-center position-relative" style={{marginTop:200}}>
                        <div className="contact-area" id="catalog">
                            <h2>{t('catalog')}</h2>
                            {
                                i18n.language == 'en' ? <p>We would like to introduce you to the new name of entertainment, Bambu Games. <br/>
                                If you want to get to know our product and games,<br/>
                                You can review our catalog with the button below.</p> : <p>Sizleri, eğlencenin yeni adıyla, Bambu Games ile tanıştırmak istiyoruz. <br/>
                                Eğer sizler de ürünümüzü ve oyunlarımızı tanımak istiyorsanız,<br/>
                                aşağıdaki buton ile kataloğumuzu inceleyebilirsiniz.
                            </p>
                            }
                            <a href="https://www.canva.com/design/DAGDFRtW6Wc/3ZYi4KHGTvAQrILVVimNDA/watch?utm_content=DAGDFRtW6Wc&utm_campaign=designshare&utm_medium=link&utm_source=editor" target = "_blank" className="pink-button"> {t('catalogButton')} <img src={require('../../assets/images/arrow.png')}/> </a>
                        </div>
                    </div>
                    <div className="col-12" style={{marginTop:200}}>
                        <div className="row">
                            <div className="col-md-6 col-12 gost">
                                <div className="row">
                                    <div className="col-4"></div>
                                    <div className="col-8 d-flex gost-section position-relative">
                                        <h3>{t('patent')}</h3>
                                        <div className="d-flex flex-row justify-content-between gost-mobile">
                                            <h2>{t('ghost')}</h2>
                                            {/* <span>Dünya'da bir ilk!</span> */}
                                        </div>
                                        {
                                            i18n.language == "en" ? <p> <b>Ghost Screen System</b> ; Inside the game table, there are ghost screen units on the screen that can be opened and closed automatically from all 4 sides. When players want to play games such as quizzes and tile rummy, these screens open automatically for every 4 players. This way, neither player can see the image in front of the other player.</p> : <p> <b>Hayalet Ekran Sistemi</b> ; Oyun masası içerisinde, ekranın üzerinde, 4 bir kenarından otomatik olarak açılıp kapanabilen hayalet ekran üniteleri mevcuttur. Oyuncular bilgi yarışmaları ve taş okey gibi oyunları oynamak istediğinde, bu ekranlar her 4 oyuncu için otomatik olarak açılır. Bu sayede, hiçbir oyuncu diğer oyuncunun önündeki görseli göremez.</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 mobile-margin d-flex align-items-center justify-content-center" style={{position:'relative'}}>
                                <img style={{maxWidth:'100%'}} src={require('../../assets/images/gost-screen.png')}/>
                                {/* <img className="right-bambu" src={require('../../assets/images/right-bambu.png')}/>
                                <img className="rock webView" src={require('../../assets/images/rock.png')}/> */}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="container-fluid second-bacground position-relative">
                <img className="rock-right" src={require('../../assets/images/rock-right.png')} />
                <img className="bambu-right-bottom" src={require('../../assets/images/bambu-right-bottom.png')} />
                <div className="row d-flex" style={{marginTop:200}}>
                    <div id="qrcode"></div>
                    <div  className="col-md-6 col-12 d-flex flex-column justify-content-center fun-area"  style={{marginTop:200}}>
                        <div className="row" >
                            <div className="col-md-4"></div>
                            <div className="col-md-8 col-12 d-flex flex-column align-items-lg-start align-items-md-start align-items-center justify-content-center" style={{gap:'40px 0px'}}>
                                
                                {/* <span >
                                    {t('fun')}
                                    <img src={require('../../assets/images/rocket.png')} />
                                </span> */}
                                {
                                    i18n.language == "en" ? <h1>DOWNLOAD THE APPLICATION, SCAN THE QR CODE, <br/> <span><b><i>
                                    CONNECT TO THE TABLE!</i></b></span> </h1> : <h1>UYGULAMAYI İNDİR,<br/>QR KODU OKUT, <br/> <span><b><i>MASAYA BAĞLAN!</i></b></span> </h1>
                                }
                                {
                                    i18n.language == "en" ? <p>With the Bambu Games application, discover the tables in your area, connect to the table of your choice using our QR reader and start having fun!</p> 
                                    : <p>BambuGames aplikasyonu ile, bulunduğunuz bölgedeki masaları keşfedin, dilediğiniz masaya QR okuyucumuzu kullanarak bağlanın ve eğlenmeye başlayın!</p>
                                }
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            i18n.language == "en" ? <h2>Download now for free!</h2> : <h2>Şimdi ücretsiz olarak indirin!</h2>
                                        }
                                    </div>
                                    <div className="col-12 d-flex flex-md-row flex-column justify-content-lg-start justify-content-md-start justify-content-center mt-5 gap-4">
                                        <a href="https://apps.apple.com/us/app/bambu-games/id6451258206" target="_blank" className="download-button"><img src={require('../../assets/images/apple.png')}/> App Store</a>
                                        <a href="https://play.google.com/store/apps/details?id=com.bambu_games_app" target="_blank" className="download-button"><img src={require('../../assets/images/googleplay.png')}/> Google Play</a>
                                        {/* <a href="#iletisim" className="pink-button"> {t('firstAreaPurpleButton')} <img src={require('../../assets/images/arrow.png')}/> </a> */}
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-md-6 col-12" style={{marginTop:200}}>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <img style={{maxWidth:'100%'}} src={require('../../assets/images/fun.png')} />
                        </div>
                    </div>
                    <div id="ne-sunuyoruz"></div>
                    <div className="col-md-6 col-12" style={{marginTop:200}}>
                        <div className="col-12 d-flex justify-content-end align-items-center">
                            <img style={{maxWidth:'100%'}} src={require('../../assets/images/phone.png')} />
                        </div>
                    </div>
                    <div className="col-md-6 col-12 d-flex flex-column justify-content-center" style={{marginTop:200}}>
                        <div className="row" >
                            <div className="col-md-8 position-relative col-12 d-flex flex-column align-items-lg-end align-items-md-end align-items-center justify-content-center" style={{gap:'40px 0px'}}>
                                
                                <span >
                                    {t('fun')}
                                    <img src={require('../../assets/images/rocket.png')} />
                                </span>
                                {
                                    i18n.language == "en" ? <h1>DOWNLOAD THE APP <br/> <span><b><i>
                                    CONNECT TO THE FUN!</i></b></span> </h1> : <h1>UYGULAMAYI İNDİR <br/> <span><b><i>EĞLENCEYE BAĞLAN!</i></b></span> </h1>
                                }
                                {
                                    i18n.language == "en" ? <p>Log into our app and follow <br/> where our tables are on the map<br/>
                                    The QR code of our mobile application is connected to the <br/> browser.<br/>
                                    Start playing during the game loading time.
                                    </p> 
                                    : <p>Aplikasyonumuza giriş yapın ve harita üzerinden <br/> masalarımızın nerede olduğunu takip edin<br/>
                                    Mobil aplikasyonumuzun QR kod <br/> tarayıcısı ile masaya bağlanın.<br/>
                                    Oyun süresi yükleyerek oynamaya <br/> başlayın.
                                </p>
                                }
                                <div className="row">
                                    <div className="col-12">
                                        <h2>{t('forDigital')}</h2>
                                    </div>
                                    <div className="col-12 d-flex justify-content-lg-end justify-content-md-end justify-content-center">
                                        <a href="#iletisim" className="pink-button"> {t('firstAreaPurpleButton')} <img src={require('../../assets/images/arrow.png')}/> </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">

                            </div>
                        </div>
                    </div>
                    {/* <div className="container" style={{marginTop:200}}>
                        <div className="row">
                            <div className="col-12">
                                {
                                    i18n.language == "en" ? 
                                    <div className="position-relative d-flex flex-column align-items-center" style={{gap:'10px 0px'}}>
                                        <h1>For the first 100 tables,instead of <span className="little-price"> 5.000$</span></h1>
                                        <h1 className="price">3.000$ !</h1>
                                    </div>
                                    :
                                    <div className="position-relative d-flex flex-column align-items-center" style={{gap:'10px 0px'}}>
                                        <h1>İlk 100 masa için,<span className="little-price"> 5.000$</span> yerine</h1>
                                        <h1 className="price">3.000$ !</h1>
                                    </div> 
                                }
                            </div>
                            <div className="col-12 d-flex justify-content-center" style={{marginTop:50}}>
                                <video className="video" muted loop autoPlay loading="lazy" src={require('../../assets/images/video.mp4')}></video>
                            </div>
                        </div>
                    </div> */}
                    
                    <div className="col-12 d-flex align-items-center justify-content-center" style={{marginTop:100}}>
                        <div className="contact-area position-relative" id="iletisim">
                            <h2>{t('deskQuest')}</h2>
                            {
                                i18n.language == "en" ? <p>We would like to introduce you to the new name of entertainment, Bambu Games. <br/>
                                If you would like to test our product, buy it or send your questions <br/>
                                If you want, you can contact us.</p> : <p>Sizleri, eğlencenin yeni adıyla, Bambu Games ile tanıştırmak istiyoruz. <br/>
                                Eğer sizler de ürünümüzü test etmek, satın almak ya da sorularınızı iletmek <br/> 
                                istiyorsanız, bize ulaşabilirsiniz.
                            </p>
                            }
                            <form onSubmit={(e)=>onSubmit(e)}>
                                <div className="row w-100" style={{gap:'20px 0px'}}>
                                    <div className="col-lg-6">
                                        <input name="name" placeholder={t('formName')} className="contact-input w-100"></input>
                                    </div>
                                    <div className="col-lg-6">
                                        <input name="mail" placeholder={t('formMail')} className="contact-input w-100"></input>
                                    </div>
                                    <div className="col-lg-6">
                                        <select name="profile" className="select-input w-100">
                                            <option value="bireysel">{t('person')}</option>
                                            <option value="kurumsal">{t('business')}</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-6">
                                        <input name="subject" placeholder={t('formSubject')} className="contact-input w-100"></input>
                                    </div>
                                    <div className="col-12">
                                        <textarea  name="message" placeholder={t('formMessage')} className="contact-input-textarea w-100" rows="5"></textarea>
                                    </div>
                                </div>
                                <button type="submit" className="pink-button" style={{marginTop:70,position:'relative',zIndex:'4'}}> {t('formButton')} <img src={require('../../assets/images/arrow.png')}/> </button>
                            </form>
                        </div>
                    </div>

                </div>
                <ToastContainer
                    position="bottom-left"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <Footer/>
            </div>
        </>
    )
}
export default Home;