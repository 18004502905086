import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t,i18n } = useTranslation();

    return(
        <>  
            <div className='container-fluid footer'>
                <img className='footer-background' src={require('../../assets/images/footer.png')}/>
                <div className='row d-flex justify-content-center align-items-center position-relative' style={{zIndex:9}}>
                    <div className='col-12 mobileView justify-content-center align-items-center'>
                        <div className='mobile-menu'>
                            <a href='#hakkimizda'>{t('menuAbout')}</a>
                            <a href='#ne-sunuyoruz'>{t('menuOffer')}</a>
                            <a href='#iletisim'>{t('menuContact')}</a>
                        </div>
                    </div>
                    <div className='col-12 mobileView align-items-center justify-content-center mt-5'>
                        <img className='logo' src={require('../../assets/images/bambugames.png')}/>
                    </div>
                    <div className='col-lg-6 col-md-12 col-12 mb-5 mt-5 d-flex justify-content-between'>
                        <div className='left-content'>
                            <a> Odunluk Mah. Odunluk Cad. <br/> N0:13/C, Nilüfer/BURSA </a>  
                            <div className='d-flex flex-row align-items-center'>
                                <img src={require('../../assets/images/phone-icon.png')}/>
                                <a href='tel:+905422151842' style={{fontSize:'24px'}}>0 (542) 215 18 42</a>
                            </div>
                            <div className='d-flex flex-row align-items-center'>
                                <img src={require('../../assets/images/mail-icon.png')}/>
                                <a style={{fontSize:'20px'}}>info@bambugames.com.tr</a>
                            </div>
                        </div>
                        <div className='middle-content webView tabletView'>
                            <img style={{marginLeft:'-25%'}} src={require('../../assets/images/bambugames.png')}/>
                        </div>
                        <div className='webView tabletView right-content position-relative'>
                            <a href='#hakkimizda'>{t('menuAbout')}</a>
                            <a href='#ne-sunuyoruz'>{t('menuOffer')}</a>
                            <a href='#iletisim'>{t('menuContact')}</a>
                        </div>
                    </div>
                </div>
                {/* <div className='row mobileView justify-content-center align-items-center'>
                    <div className='col-12 d-flex justify-content-center align-items-center'>
                        <div className='mobile-menu'>
                            <a href='#hakkimizda'>{t('menuAbout')}</a>
                            <a href='#ne-sunuyoruz'>{t('menuOffer')}</a>
                            <a href='#iletisim'>{t('menuContact')}</a>
                        </div>
                    </div>
                    <div className='col-12 d-flex align-items-center justify-content-center mt-5'>
                        <img className='logo' src={require('../../assets/images/bambugames.png')}/>
                    </div>
                    <div className='col-12 mt-5 mb-5'>
                        <div className='left-content'>
                            <a> Odunluk Mah. Odunluk Cad. N0:13/C, Nilüfer/BURSA </a> 
                            <div className='d-flex flex-row align-items-center'>
                                <img src={require('../../assets/images/phone-icon.png')}/>
                                <a style={{fontSize:'28px'}}>0552 730 62 42</a>
                            </div>
                            <div className='d-flex flex-row align-items-center'>
                                <img src={require('../../assets/images/mail-icon.png')}/>
                                <a style={{fontSize:'28px'}}>info@bambugames.com.tr</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row tabletView justify-content-center align-items-center'>
                    <div className='col-12 mb-5 mt-5 d-flex justify-content-between'>
                        <div className='left-content'>
                            <a> Odunluk Mah. Odunluk Cad. <br/> N0:13/C, Nilüfer/BURSA </a>  
                            <div className='d-flex flex-row align-items-center'>
                                <img src={require('../../assets/images/phone-icon.png')}/>
                                <a style={{fontSize:'28px'}}>0552 730 62 42</a>
                            </div>
                            <div className='d-flex flex-row align-items-center'>
                                <img src={require('../../assets/images/mail-icon.png')}/>
                                <a  style={{fontSize:'28px'}}>info@bambugames.com.tr</a>
                            </div>
                        </div>
                        <div className='middle-content'>
                            <img style={{marginLeft:'-25%'}} src={require('../../assets/images/bambugames.png')}/>
                        </div>
                        <div className='right-content position-relative'>
                            <a href='#hakkimizda'>{t('menuAbout')}</a>
                            <a href='#ne-sunuyoruz'>{t('menuOffer')}</a>
                            <a href='#iletisim'>{t('menuContact')}</a>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default Footer;